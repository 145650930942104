<template>
  <div>

    <div id="img-background">
    </div>

    <header>
      <h1>Politique de confidentialité</h1>
      <h2>Bougez simplement</h2>
    </header>

    <main>
      <h2>Politique de <span class="title_underline"> confidentialité </span></h2>

      <div class="white_bg">
        <p>Dans le cadre de son activité de cours de sport à distance, la <strong>Société Physic’AL</strong>,
          représentée par Anne-Laure GRANDJEAN, est amenée à collecter et traiter les données personnelles de ses
          visiteurs. Les activités pour lesquelles la société est amené à traiter des données sensibles sont les
          suivantes :</p>
        <ul style="list-style-type: none">
          <li><strong>Vente d’abonnements :</strong> avec la création d’un compte client (nom/prénom, coordonnées
            postales, email, tél, coordonnées bancaires dans les cas de paiement par CB). Ces données sont nécessaires
            au traitement de votre commande et à la gestion de nos relations commerciales.
          </li>
          <li><strong>Suivi clientèle :</strong> Physic’AL tient un fichier client sous format pdf sauvegardé sur son
            ordinateur.
          </li>
          <li><strong>Analyse statistique de données :</strong> pour étude de marché et reporting.</li>
          <li><strong>Réponse aux prises de contact :</strong> avec un formulaire présent sur la page « Contact » du
            site web.
          </li>
        </ul>
        <p>La présente politique de confidentialité a pour vocation de fournir aux internautes une information
          synthétique et globale sur les traitements de données à caractère personnel opérés par la société de commerce.
          En accédant à notre site Web et en utilisant nos services, vous reconnaissez avoir lu et compris la présente
          Politique de confidentialité, ainsi que les pratiques de recueil et de traitement des informations décrites
          dans ce document.</p>
        <p>Ce document a été mis à jour pour la dernière fois le 02/01/2022. Anne-Laure GRANDJEAN se réserve le droit de
          faire évoluer la présente Politique de confidentialité afin de se conformer aux modifications des lois et
          réglementations en vigueur. Veuillez donc le consulter régulièrement afin de rester informé(e).</p>
        <h3>Définitions</h3>
        <p>Physic’AL accorde une importance particulière au respect de la vie privée des Utilisateurs et de la
          confidentialité de leurs données personnelles, et s’engage ainsi à traiter les données dans le respect des
          lois et règlementations applicables, et notamment la Loi n° 78-17 du 6 janvier 1978 relative à l’informatique,
          aux fichiers et aux libertés (la “Loi Informatique et Liberté”), et le Règlement (UE) 2016/679 du Parlement
          européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du
          traitement des données à caractère personnel et à la libre circulation de ces données (le “RGPD”).</p>
        <ul style="list-style-type: none">
          <li><strong>Donnée à caractère personnel :</strong> constitue une donnée à caractère personnel toute
            information relative à une personne physique identifiée ou identifiable, c’est-à-dire qui peut être
            identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs
            éléments qui lui sont propres.
          </li>
          <li><strong>Traitement de données à caractère personnel :</strong> constitue un traitement de données à
            caractère personnel toute opération portant sur de telles données, quel que soit le procédé utilisé, et
            notamment la collecte, l’enregistrement, l’organisation, la conservation, l’adaptation ou la modification,
            l’extraction, la consultation, l’utilisation, la communication par transmission, diffusion ou toute autre
            forme de mise à disposition, le rapprochement ou l’interconnexion, ainsi que le verrouillage, l’effacement
            ou la destruction.
          </li>
          <li><strong>Cookie :</strong> un cookie est une information déposée sur le disque dur d’un internaute par le
            serveur du site qu’il visite. Il contient plusieurs données : le nom du serveur qui l’a déposé, un
            identifiant sous forme de numéro unique, éventuellement une date d’expiration. Ces informations sont parfois
            stockées sur l’ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des
            informations.
          </li>
        </ul>

        <h3>Responsable légal et responsable du traitement des données</h3>
        <ul style="list-style-type: none">
          <li><u>Représentant légal :</u> Anne-Laure Grandjean</li>
          <li><u>Adresse postale :</u> 34 rue du général courtot, 90700 Châtenois-les-Forges</li>
          <li><u>Tél :</u> +33 (0) 6 86 62 07 15</li>
          <li><u>Email :</u> physic.al@yahoo.com</li>
          <li><u>SIRET :</u> 81213625700019</li>
        </ul>
        <h3>Recueil des informations collectées</h3>
        <p>Physic’AL recueille des informations vous concernant dans les cas de figure suivants :</p>
        <p><u>Vente de produits commerciaux : abonnements</u></p>
        <p>La création d’un compte client (nom/prénom, coordonnées postales, email, tél, coordonnées bancaires dans les
          cas de paiement par CB) est exigée pour toute commande en ligne. Ces données sont nécessaires au traitement de
          votre commande et à la gestion de nos relations commerciales. C’est vous et vous seul qui décidez si vous
          souhaitez nous communiquer ou pas vos données personnelles, en d’autres termes ces indications nous sont
          communiquées par vos soins sur une base volontaire. Pour les données collectées en ligne, les communications
          sur la partie cliente sont cryptées entre le poste de l’internaute et nos serveurs (zone sécurisée HTTPS). Les
          données sont ensuite sauvegardées sur nos serveurs sécurisés.
        </p>
        <p><u>Prise de contact volontaire via le formulaire :</u></p>
        <p>Physic’AL propose à ces internautes, via l’intermédiaire d’un formulaire de contact sécurisé, de prendre
          contact avec la chef de projets. Ladite prise de contact n’est pas obligatoire pour la bonne consultation du
          site web, un numéro de téléphone est indiqué.
        </p>
        <p><u>Protection des mineurs :</u></p>
        <p>Les mineurs ne doivent pas transmettre de données individuelles à des sites Internet à but commercial sans
          l’autorisation de leurs parents. Physic’AL ne collectera en aucun cas délibérément les données individuelles
          d’enfants, ni ne les utilisera, ni ne les mettra à disposition, ni ne les transmettra à quiconque sans y être
          autorisé.
        </p>
        <h3>Utilisation de vos données personnelles</h3>
        <p>Dans le cadre de la vente en ligne, vos données personnelles seront utilisées pour finaliser votre commande.
          Vos coordonnées postales seront utilisées dans le cadre de la livraison que vous aurez choisie (domicile ou
          point relais) et pour la facturation. Vos données de contact (email et téléphone) seront utilisées pour vous
          assurer un suivi de commande. Dans le cas de vos coordonnées bancaires :
        </p>
        <ul style="list-style-type: none">
          <li><u>Carte bancaire :</u> elles ne seront sauvegardées qu’à votre demande sinon elles seront supprimées
            après finalisation de la commande.
          </li>
          <li><u>Paypal :</u> vos logins de connexion ne seront pas conservés sur notre plateforme. Ils vous incombent
            de les sauvegarder ou non sur votre navigateur.
          </li>
          <li><u>Chèque :</u> ce dernier sera encaissé dès réception par voie postale.
          </li>
        </ul>
        <h3>Tiers avec lesquels nous partageons vos données personnelles</h3>
        <p>Les données à caractère personnel collectées sont destinées aux services commerciaux et webmarketing de
          Physic’AL. Les données sont seulement communiquées, dans la mesure du nécessaire, afin d’exécuter votre
          commande, de la livrer ou de traiter une demande. Pour des déroulements d’affaires précis (comme par ex.
          l’envoi de paquets), il est nécessaire de transmettre vos données individuelles à des tiers (par ex. la
          poste).
        </p>
        <h3>Durée de conservation de vos données</h3>
        <p>Illimité, ou plus précisément nous conserverons vos données tant que vous ne supprimez pas votre
          compte-client dans le cadre de la vente en ligne. Physic’AL sauvegardera vos emails tant que la société
          utilisera la plateforme Sendinblue pour la diffusion de sa newsletter. En cas de suppression de l’outil, vos
          informations seront définitivement supprimées de la plateforme.
          Vous pouvez à tout moment faire appel à votre droit à l’oubli ou à votre droit d’opposition (voir paragraphe
          suivant).
        </p>
        <h3>Vos droits en tant qu’adhérent à notre site web</h3>
        <p>En tant qu’internaute, vous avez des droits en termes de protection et d’accès à vos données
          personnelles.</p>
        <p><strong>OU/ET dans le cas d’un fichier-client :</strong></p>
        <p>Physic’AL possède un fichier-clients alimenté par un réseautage et une relation clientèle. À aucun moment vos
          informations ne sont ni diffusées, ni vendus à des fins commerciales. Les données à caractère personnel
          collectées sont destinées aux services commerciaux. Elles sont sauvegardées sur un disque dur sécurisé protégé
          par un mot de passe. Vous pouvez à tout moment contacter Physic’AL, vos droits sont les suivants :
        </p>
        <ul style="list-style-type: none">
          <li><u>Droit de rectification :</u> vous pouvez nous contacter à tout moment pour modifier les
            données vous concernant. Vos informations seront modifiées dans un délai de 48h maximum.
          </li>
          <li><u>Droit à l’oubli :</u> sur votre demande, nous nous engageons à effacer définitivement toutes
            données vous
            concernant dans un délai de 30 jours. Si vous souhaitez exercer ce droit pour votre compte-client ou que
            vous ne désirez plus recevoir d’offres commerciales de Physic’AL, vous devez nous en informer par écrit ou
            e-mail, en nous indiquant vos noms, prénom, adresse et n° de client.
          </li>
          <li><u>Droit à la portabilité :</u> si vous nous le demandez, nous nous engageons à exporter toutes
            vos données et à vous les transférer sous un format sécurisé (protégé par le mot de passe de votre choix).
          </li>
          <li><u>Droit d’opposition :</u> vous pouvez à tout moment vous désinscrire à la newsletter. Le lien
            se trouve
            en bas de l’email. L’action est immédiate mais demande une confirmation de votre part. Vous pouvez vous
            réinscrire à tout moment également.
          </li>
          <li><u>Droit d’accès :</u> nous sommes transparents sur les données que nous collectons et l’usage
            que nous en faisons.
          </li>
        </ul>
        <h3>Cookies</h3>
        <p>Le site de Physic’AL utilise des cookies qui ont pour finalité de faciliter la navigation sur le site, de
          mesurer l’audience du site ou encore de permettre le partage de pages du site. Les cookies que nous collectons
          sont les suivants :</p>
        <ul style="list-style-type:none; ">
          <li><u>Les cookies analytiques :</u> Ces cookies permettent de connaître l’utilisation et les performances du
            site et d’en améliorer le fonctionnement en procédant à des analyses de fréquentation des pages
            d’information, en effectuant un suivi des taux d’ouverture, des taux de clics et des taux de rebond au
            niveau individuel.
          </li>
          <li><u>Les cookies de boutons de partage :</u> Ces cookies sociaux permettent aux utilisateurs de partager des
            pages et du contenu sur les réseaux sociaux tiers via les boutons sociaux de partage.
          </li>
        </ul>
        <h3>Gestion des cookies</h3>
        <p>Les internautes ont la possibilité d’accepter ou de refuser les cookies sur le site ou de les refuser une
          fois pour toutes en paramétrant leur navigateur. Si l’internaute choisit de refuser l’ensemble des cookies, la
          navigation accédant à certaines pages du site sera réduite.
        </p>
        <p><a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">Maîtriser les cookies de son
          navigateur (site de la CNIL)</a></p>
        <h3>Comment Physic’AL sécurise vos données personnelles ?</h3>
        <p>Pour les données collectées en ligne, les communications sur la partie cliente sont cryptées entre le poste
          de l’internaute et nos serveurs (zone sécurisée HTTPS). Les données sont ensuite sauvegardées sur nos serveurs
          sécurisés hébergés chez Infomaniak. Ce fournisseur d’hébergement s’engage à ne pas réutiliser les données
          personnelles stockées sur les serveurs sécurisés de leurs clients. Infomaniak vous garantit qu’il ne traite
          pas vos informations en dehors de l’Union européenne ou de tout pays reconnu par la Commission européenne
          comme disposant d’un niveau de protection des données à caractère personnel suffisant (au regard de la
          protection de la vie privée, des libertés et droits fondamentaux des personnes, ainsi qu’à l’égard de
          l’exercice des droits correspondants).
        </p>
        <p><strong>Pour l’ensemble de ses services, Infomaniak s’engage à mettre en place :</strong></p>
        <ul style="list-style-type: none">
          <li><strong>Des mesures de sécurité physique </strong>afin d’empêcher l’accès aux infrastructures par des
            personnes non autorisées ;
          </li>
          <li><strong>Un personnel de sécurité </strong>chargé de veiller à la sécurité physique de nos locaux 24 heures
            sur 24 et 7 jours sur 7 ;
          </li>
          <li><strong>Un système de gestion des permissions </strong>permettant de limiter l’accès aux locaux et aux
            données aux
            seules personnes habilitées, dans le cadre de leurs fonctions et de leurs périmètres d’activité ;
          </li>
          <li><strong>Un système d’isolation physique et/ou logique</strong> (selon les services) des clients entre
            eux ; des processus
            d’authentification forts des utilisateurs et administrateurs grâce, notamment, à une politique stricte de
            gestion des mots de passe et au déploiement de certaines mesures de double authentification comme YubiKey ;
          </li>
          <li><strong>Des processus et dispositifs</strong> permettant de tracer l’ensemble des actions réalisées sur notre système
            d’information et d’effectuer, conformément à la réglementation en vigueur, des rapports en cas d’incident
            affectant les données de nos clients.
          </li>
        </ul>

      </div>


    </main>


    <footer>
      <FooterPage/>
    </footer>

  </div>
</template>

<script>

import FooterPage from "../components/FooterPage";


export default {
  name: "Politique",
  components: {FooterPage},
}
</script>


<style scoped lang="less">


#img-background {
  height: 40vh;
  width: 100vw;
  position: absolute;
  left: 0;
  top: 0;

  background-color: #70707020;

  background-image: url("../assets/media/Coach_fitness-fond_flou.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: darken;

  z-index: -10;
}

header {
  padding: 0 40px;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 40px 0;

  & > * {
    margin-bottom: 50px;
  }

  & > h1 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    font-size: 60px;
    max-width: 423px;
    width: 100%;
  }

  & > h2 {
    font-family: 'Merriweather', serif;
    font-weight: lighter;
    font-size: 30px;
    max-width: 423px;
    width: 100%;
  }
}

main {
  display: flex;
  flex-direction: column;

  font-family: 'Urbanist', sans-serif;

  & h2 {
    font-size: 40px;
    margin: 20px auto;
    display: inline-block;

    font-family: 'Josefin Sans', sans-serif;
    font-weight: normal;
    text-align: center;
  }
}

main {
  margin-top: 50px;
  width: 100%;
  background: var(--light_gray);

  display: flex;
  align-items: center;

  & h2 {
    font-size: 40px;
    margin: 20px auto;
    display: inline-block;

    font-family: 'Josefin Sans', sans-serif;
    font-weight: normal;
    text-align: center;
  }
}

.white_bg {
  margin: 25px;
  padding: 70px;

  width: calc(100% - 100px);
  width: -webkit-fill-available;

  background: white;

  font-size: 20px;

  & h3 {
    font-size: 22px;
    margin: 20px;
    padding-top: 20px;
  }

  & p {
    margin-bottom: 10px;
  }
}

</style>
